import { AMaterialLessonBlockTemplate } from "../../models";

const videos = [
  "https://www.youtube.com/embed/jL88IAxoYOk?si=40ShiLZgZFklgLtD",
  "https://www.youtube.com/embed/oY3XNJUrryI?si=sVTvhbGgOf_OcVlS",
];
const text = {
  title: {
    sample: "this is a text title",
    size: "61px",
    color: "var(--primary)",
  },
  subtitle: {
    sample: "this is a text subtitle",
    size: "14px",
    color: "var(--accent)",
  },
  desc: {
    sample: "this is a text description",
    size: "16px",
    color: "black",
  },
};

export const VideoContent: AMaterialLessonBlockTemplate[] = [
  {
    name: "Video Content",
    template: {
      name: "Video Content",
      groups: [
        // Upper side:
        {
          items: [
            {
              type: "video",
              value: videos[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "50%",
          },
        },
        // Bottom side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                // height: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
                fontWeight: "bold",
              },
            },
          ],
          style: {
            width: "100%",
            height: "50%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "40px",
      },
    },
  },
];
